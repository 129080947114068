import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import styles from './layout.module.scss'
import favicon from '../images/northvolt.png'

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'twitter:card',
              content: 'summary',
            },
            {
              name: 'twitter:site',
              content: '@northvolt',
            },
            {
              name: 'twitter:creator',
              content: '@northvolt',
            },
            {
              property: 'og:url',
              content: 'https://chronicles.northvolt.com',
            },
            {
              property: 'og:locale',
              content: 'https://chronicles.northvolt.com',
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              property: 'og:site_name',
              content: 'Northvolt Chronicles',
            },
            {
              property: 'og:title',
              content: 'Northvolt Chronicles',
            },
            {
              property: 'og:description',
              content:
                'In 2017, a start-up announced a bold and simple plan: develop the world’s greenest battery cell and establish one of Europe’s largest battery factories. Here is the story of Northvolt.',
            },
            {
              property: 'og:image',
              content:
                'https://images.ctfassets.net/vz0hbrlgfmvn/section-10.poster/ddc5f1939699c4a2203d2f183dc7ae7b/10.0.jpg',
            },
            {
              name: 'description',
              content:
                'In 2017, a start-up announced a bold and simple plan: develop the world’s greenest battery cell and establish one of Europe’s largest battery factories. Here is the story of Northvolt.',
            },
            {
              name: 'keywords',
              content: 'northvolt, battery, factory, lithium-ion',
            },
            {
              name: 'HandheldFriendly',
              content: 'Truen',
            },
            {
              name: 'MobileOptimized',
              content: '320',
            },
            {
              name: 'viewport',
              content: 'width=device-width,initial-scale=1',
            },
            {
              name: 'theme-color',
              content: '#ffffff',
            },
          ]}
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/png',
              href: `${favicon}`,
            },
            {
              rel: 'icon',
              type: 'image/png',
              href: `${favicon}`,
            },
            {
              rel: 'canonical',
              href: '/web/tweet-button',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div className={styles.page}>{children}</div>
      </>
    )}
  />
)

export default Layout
